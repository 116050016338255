import client from "./http";

class KeyDatesService {
  constructor(axios) {
    this.axios = axios;
  }

  getList(programId, search) {
    return this.axios.get(`/programs/${programId}/key-dates`, {
      params: { search: search },
    });
  }

  getKeyDate(programId, keyDateId) {
    return this.axios.get(`/programs/${programId}/key-dates/${keyDateId}`);
  }

  add(programId, data) {
    return this.axios.post(`/programs/${programId}/key-dates`, data);
  }

  update(programId, keyDateId, data) {
    return this.axios.patch(`/programs/${programId}/key-dates/${keyDateId}`, data);
  }

  delete(programId, keyDateId) {
    return this.axios.delete(`/programs/${programId}/key-dates/${keyDateId}`);
  }
}

const keyDatesService = new KeyDatesService(client);
export default keyDatesService;
